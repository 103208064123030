import DarkMode from "components/header/DarkMode";
import { FAVORITE_URL, JAVASCRIPT_VOID, MY_REVENUE_URL, POINT_AFREECATV } from "constant/config";
import { getLogoutUrl, getStationUrl, setClickLogapi } from "helpers";
import React, { useCallback } from "react";
import { Trans } from "react-i18next";

export const UserArea = (props) => {
    const { authenticationReducer, handleSwitchUserArea, userArea } = props;
    const { user_id, note_cnt = 0 } = authenticationReducer;
    const lang = document.documentElement.lang;

    const handleSetClickLog = useCallback((type) => {
        if (type) {
            setClickLogapi("gnb_00000001", { button_type: type });
        }
    }, []);
    // 타입에 따라 팝업 오픈
    const openPopup = (popupType) => {
        let popupAdress = ''
        let popupWidth = 500
        let popupHeight = 500
        switch (popupType) {
            case 'afreeca_pay':
                popupWidth = 496
                popupHeight = 810
                popupAdress = `${POINT_AFREECATV}/AfreecaPay/AfreecaPayMgmt.asp`;
                break;
            default:
                break;
        }
        // 팝업 창 열리는 좌표 지정
        // 문서 길이가 긴 경우 중앙정렬시 화면에서 안보이는 문제가 있어 좌우만 브라우저 크기를 기준으로 세팅
        const popupX = (document.body.offsetWidth/2)-(popupWidth/2)
        const popupY = (window.screen.height/2)-(popupHeight/2)

        if(popupAdress) {
            window.open(popupAdress, "popupAfreecaPay", 'width='+ popupWidth +', height='+ popupHeight +', left='+ popupX + ', top='+ popupY)
        }
    }

    return (
        <div
            className="loginUserMenu"
            onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
            }}
        >
            <div className="UserMenu_wrap" style={{display: userArea === 'main' ? "block" : "none"}}>
                <div className="btn_quick">
                    <a
                        href={getStationUrl(user_id)}
                        target="_blank"
                        onClick={() => handleSetClickLog("station")}
                        rel="noopener noreferrer"
                        className="mybs"
                    >
                        <Trans>방송국</Trans>
                    </a>
                    <a href={FAVORITE_URL} onClick={() => handleSetClickLog("favorite")} className="favorite">
                        <Trans>즐겨찾기</Trans>
                    </a>
                </div>
                <div className="menuList_wrap">
                    <ul className="menuList">
                        <li>
                            <a
                                className="my_message"
                                href="//note.afreecatv.com/app/index.php"
                                target="_top"
                                onClick={() => handleSetClickLog("note")}
                            >
                                <span>
                                    <Trans>쪽지</Trans>
                                </span>
                                
                                <span>
                                    <span className="memo">{ note_cnt }</span>
                                    {lang === "ko" && (
                                        <Trans>개</Trans>
                                    )}
                                </span>
                                {note_cnt > 0 && <span className="new">New</span>}
                            </a>
                        </li>
                    </ul>
                    <ul className="menuList">
                        {lang === "ko" && (
                            <li>
                                <a
                                    className="my_afpay"
                                    href={JAVASCRIPT_VOID}
                                    onClick={() => {handleSetClickLog("afreecapay_setting"); openPopup("afreeca_pay")}}
                                >
                                    <span>
                                        <Trans>아프리카페이 관리</Trans>
                                    </span>
                                </a>
                            </li>
                        )}
                        <li>
                            <a
                                className="my_item"
                                href="https://point.afreecatv.com/report/AfreecaUseList.asp"
                                target="_top"
                                onClick={() => handleSetClickLog("item")}
                            >
                                <span>
                                    <Trans>아이템</Trans>
                                </span>
                            </a>
                        </li>
                        <li>
                            <a
                                className="my_balloon"
                                href="https://point.afreecatv.com/Report/AfreecaBalloonList.asp"
                                target="_top"
                                onClick={() => handleSetClickLog("balloon")}
                            >
                                <span>
                                    <Trans>별풍선</Trans>
                                </span>
                            </a>
                        </li>
                        <li>
                            <a
                                className="my_gd"
                                href="https://point.afreecatv.com/Subscription/SubscriptionList.asp"
                                onClick={() => handleSetClickLog("subscription")}
                            >
                                <span>
                                    <Trans>구독</Trans>
                                </span>
                            </a>
                        </li>
                    </ul>
                    {lang === "ko" && (
                    <ul className="menuList">
                            <React.Fragment>
                                <li>
                                    <a  
                                        className="my_drops" 
                                        href="https://drops.afreecatv.com/" 
                                        target="_top" 
                                        onClick={() => handleSetClickLog("drops")}
                                    >
                                        <span>
                                            <Trans>드롭스</Trans>
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="my_spon"
                                        href="https://adballoon.afreecatv.com/savings.php?view=myadballoon"
                                        target="_top"
                                        onClick={() => handleSetClickLog("adballoon")}
                                    >
                                        <span>
                                            <Trans>애드벌룬</Trans>
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="my_point"
                                        href="https://mypoint.afreecatv.com/index.php"
                                        target="_top"
                                        onClick={() => handleSetClickLog("point_token")}
                                    >
                                        <span>
                                            <Trans>포인트</Trans> · <Trans>크레딧</Trans>
                                        </span>
                                    </a>
                                </li>
                                
                                <li>
                                    <a href={JAVASCRIPT_VOID} className="my_profit" onClick={(e)=>{
                                        e.stopPropagation();
                                        handleSwitchUserArea('profit');
                                    }}>
                                        <span>
                                            <Trans>통계</Trans> · <Trans>수익</Trans> · <Trans>광고</Trans>
                                        </span>
                                    </a>
                                </li>
                            </React.Fragment>
                    </ul>
                    )}
                
                    {lang === "ko" && (
                        <React.Fragment>
                            <ul className="menuList">
                                <li>
                                    <a
                                        className="my_dashboard"
                                        href="//dashboard.afreecatv.com/index.php"
                                        target="_blank"
                                        onClick={() => handleSetClickLog("dashboard")}
                                    >
                                        <span>
                                            <Trans>외부장치 방송 설정</Trans>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                            <ul className="menuList">
                                <li>
                                    <a
                                        className="my_shopping"
                                        href="//shopfreeca.afreecatv.com/mypage"
                                        target="_blank"
                                        onClick={() => handleSetClickLog("shopping")}
                                    >
                                        <span>
                                            <Trans>MY 쇼핑</Trans>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                            <DarkMode />
                        </React.Fragment>
                    )}
                    <ul className="menuList">
                        <li>
                            <a
                                href="https://member.afreecatv.com/app/user_info.php"
                                target="_top"
                                onClick={() => handleSetClickLog("my_info")}
                                className="my_info"
                            >
                                <span>
                                    <Trans>내 정보</Trans>
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href={getLogoutUrl()} onClick={() => handleSetClickLog("logout")} className="userLogout">
                                <span>
                                    <Trans>로그아웃</Trans>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="depth_2" style={{display: userArea === 'profit' ? "block" : "none"}}>
                <div className="back">
                    <button type="button" onClick={(e)=>{
                        e.stopPropagation();
                        handleSwitchUserArea('main')
                    }}>
                        <Trans>이전</Trans>
                    </button>
                </div>
                <ul className="menuList">
                    <li>
                        <a
                            className="my_stat"
                            href="//broadstatistic.afreecatv.com"
                            target="_top"
                            onClick={() => handleSetClickLog("stat")}
                        >
                            <span>
                                <Trans>통계</Trans>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a className="my_profit" href={MY_REVENUE_URL} target="_top" onClick={() => handleSetClickLog("profit")}>
                            <span>
                                <Trans>수익</Trans>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a
                            className="my_bene"
                            href="https://adrevenue.afreecatv.com "
                            target="_top"
                            onClick={() => handleSetClickLog("advertising")}
                        >
                            <span>
                                <Trans>광고</Trans>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            
        </div>
    );
};
